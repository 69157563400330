import {
  Layout, Home, Category, Novel, RequestNovel,
  Dashboard, NovelsDahboard, Wishlist, Support,
  Profile, Privacy, ResetPassword
} from "components";
import { Routes, Route } from "react-router-dom";

const novels = [
  { id: 1, name: "novel1" },
  { id: 5, name: "novel2" },
  { id: 6, name: "novel3" },
];

const staticRoutes = [
  { path: "/", element: <Home />, index: true },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/home", element: <Home /> },
  { path: "/register", element: <Home /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/category/:id", element: <Category /> },
  { path: "/novel/:id", element: <Novel /> },
  { path: "/novels", element: <NovelsDahboard /> },
  { path: "/novels/:id", element: <NovelsDahboard /> },
  // { path: "/wishlist", element: <Wishlist /> },
  { path: "/support", element: <Support /> },
  { path: "/profile", element: <Profile /> },
];

export const Router = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      {staticRoutes.map(({ path, element, index }) => (
        <Route key={path} path={path} element={element} index={index} />
      ))}

      {/* Dynamic Request Novel Routes */}
      {novels.map(({ id }) => (
        <Route key={id} path={`/request/${id}`} element={<RequestNovel NovelId={id} />} />
      ))}
    </Route>

    {/* Future Dashboard Routes (Uncomment when needed) */}
    {/* <Route path="/dashboard" element={<Dashboard />}>
      <Route index element={<NovelsDahboard />} />
      <Route path="novels" element={<NovelsDahboard />} />
      <Route path="wishlist" element={<Wishlist />} />
      <Route path="support" element={<Support />} />
      <Route path="profile" element={<Profile />} />
    </Route> */}
  </Routes>
);

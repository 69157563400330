import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DismissIcon } from "../../assets/img/dismiss.svg";
import { useAuth } from "hooks";
import i18next from "i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMediaQuery } from "@mui/material";

export const RequestNovel = ({ HideRequest, Animations, setShowRequest }) => {
  const { t } = useTranslation();
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState(false);
  const [sentCoupon, setSentCoupon] = useState("");
  const [discount, setDescount] = useState("");
  const [subtotal, setsubtotal] = useState("");
  const [finaltotal, setFinaltotal] = useState("");
  const [totalWithDiscount, setTotalWithDiscount] = useState("");
  const { bearerToken, setLoginShow, user } = useAuth();
  const [isRedeemVisible, setIsRedeemVisible] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0)
  const [clicked, setClicked] = useState(false)
  const matches = useMediaQuery("(min-width:870px)");
  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
    setCouponCodeError(false);
  };
  const NovelDetails = JSON.parse(localStorage.getItem('novelDetails')) || [];

  const handleApplyCouponClick = () => {
    if (!couponCode) {
      setCouponCodeError(true);
      return;
    }
    const novelIds = NovelDetails.map(novel => `novel_id[]=${novel.id}`).join('&');

    const url = `${process.env.REACT_APP_BASE_URL}/check-coupon?code=${couponCode}&${novelIds}`;
    // const options = {
    //   headers: {
    //     Authorization: `Bearer ${bearerToken}`,
    //   },
    // };
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          console.log(data);
          setDescount(data.data.discount);
          setsubtotal(data.data.sub_total)
          setTotalWithDiscount(data.data.total_after_discount);
          setFinaltotal(data.data.total_price)
          setSentCoupon(couponCode);
          setClicked(true)
        } else {
          setSentCoupon("");
          setDescount("");
          setTotalWithDiscount("");
          throw data.message;
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert(error);
      });
  };
  const handleConfirmClick = () => {

    const url = `${process.env.REACT_APP_BASE_URL}/checkout`;

    const bodyParams = new URLSearchParams();
    
    NovelDetails.forEach(novel => {
      bodyParams.append('novel_id[]', novel.id);
    });
    
    if (sentCoupon) {
      bodyParams.append('promo_code', sentCoupon);
    }
  
    
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: bodyParams.toString(),
    }

    fetch(url, options)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          console.log(data)
          setCouponCode("");
          setSentCoupon("");
          localStorage.removeItem('novelDetails')
          const paymentUrl = data.data.payment_session_url;
          if (paymentUrl) {
            window.open(paymentUrl, '_blank');
            setShowRequest(false)
          } else {
            console.error("Payment session URL is empty.");
          }
        } else {
          HideRequest();
          throw data.message;
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert(error);
      });
  };
  const toggleRedeemVisibility = () => {
    setIsRedeemVisible(!isRedeemVisible);
  };
  const handleRemove = (id) => {
    const updatedNovels = NovelDetails.filter((n) => n.id !== id);
    localStorage.setItem('novelDetails', JSON.stringify(updatedNovels));
    window.location.reload(true)

}
useEffect(() => {
  let total = 0;

  for (let i = 0; i < NovelDetails.length; i++) {
    total += Number(NovelDetails[i].price); 
  }

  setTotalPrice(total.toFixed(2)); 
}, [NovelDetails]);

  return (
    <div
      className={`request-novel ${Animations ? "fade" : ""} ${
        i18next.language === "ar" ? "ar" : ""
      }`}
    >
      {NovelDetails && NovelDetails.length === 0 ? (
        <div className="card">
          <div className="card__header">
            <h1>{t('No Items Yet')}</h1>{" "}
            <DismissIcon className="dismiss-icon" onClick={HideRequest} />
          </div>
        </div>
      ) : (
        <div className="card" style={{ overflowY: "scroll" }}>
          <div className="card__header">
            <h1>{t("Your Cart")}</h1>
            <DismissIcon className="dismiss-icon" onClick={HideRequest} />
          </div>

          {NovelDetails && NovelDetails.map((n) => (
            <div className="card__informations">
              <div className="image-background">
                  <img
                    src={`${process.env.REACT_APP_LARAVEL_BASE_URL}/${n.images[0]}`}
                  />
              </div>
              <div className="titles">
                <h2>{n.title}</h2>
                <p>{n.price}</p>
              </div>
              <DeleteOutlineIcon
                sx={{
                  color: "#f9aa10",
                  cursor: "pointer",
                  ml: "40px",
                  mt: "10px",
                }}
                onClick={()=>{handleRemove(n.id)}}
              />
            </div>
          ))}

          <div className="card__balance">
            <div className="subtotal">
              <h2 className="title">
                {totalWithDiscount ? t("Subtotal") :!clicked ? t("Total") : null}
              </h2>
              <h2 className="value">
                {NovelDetails && NovelDetails.price_after_discount 
                  ?  NovelDetails.price_after_discount +"SAR"
                  : !clicked ? totalPrice + "SAR" : null}
              </h2>
            </div>
            {discount && (
              <>
              <div className="discount" style={{textDecoration:'line-through'}}>
                <h2 className="title">{t("Sub Total")}</h2>
                <h2 className="value">SAR{totalPrice}</h2>
              </div>
              <div className="discount">
                <h2 className="title">{t("Discount")}</h2>
                <h2 className="value">SAR{discount}</h2>
                </div>
              <div className="discount">
                <h2 className="title">{t("Total")}</h2>
                <h2 className="value">SAR{finaltotal}</h2>
              </div>
              </>
            )}
            {totalWithDiscount && (
              <div className="total">
                <h2 className="title" >{t("Total")}</h2>
                <h2 className="value" >SAR{totalWithDiscount}</h2>
              </div>
            )}
          </div>

          {/* <div className="card__payment">
            <h2>
                {t('Payment Details')}
            </h2>
            <div className={`number-card ${cardNumberError ? 'error' : ''}`}>
                <input type="text" placeholder={t('Card Number')} value={cardNumber} onChange={(e)=>{setCardNumber(e.target.value); setCardNumberError(false);}} />
            </div>
            <div className="info-card">
                <div className={`exp ${expiryDateError ? 'error' : ''}`}>
                    <input type="text" placeholder={t('Exp. Date')} value={expiryDate} onChange={(e)=>{setExpiryDate(e.target.value); setExpiryDateError(false);}} />
                </div>
                <div className={`cvv ${cvvError ? 'error' : ''}`}>
                    <input type="text" placeholder={t('CVV')} value={cvv} onChange={(e)=>{setCvv(e.target.value); setCvvError(false);}} />
                </div>
            </div>
        </div> */}

          <div className="card__redeem">
            <div className="redeem-header" onClick={toggleRedeemVisibility}>
              <h2
                style={{ cursor: "pointer", marginTop: matches ? "" : "50px" }}
              >
                {isRedeemVisible ? t("Hide Redeem Code") : t("Redeem Code")}
                <ArrowDropDownIcon />
              </h2>
            </div>
            {isRedeemVisible && (
              <div className={`redeem-apply ${couponCodeError ? "error" : ""}`}>
                <input
                  type="text"
                  value={couponCode}
                  onChange={(e) => {
                    handleCouponCodeChange(e);
                  }}
                />
                <button
                  className="apply-button"
                  onClick={() => {
                    handleApplyCouponClick();
                  }}
                >
                  {t("Apply")}
                </button>
              </div>
            )}
          </div>
          <div className="card__notice" style={{ paddingBottom: "50px" }}>
            <p>
              {t(
                "By selecting [Confirm Purchase], you agree to complete the purchase in Accordance with the Terms of Service before using this content."
              )}
            </p>
          </div>

         {user ? <button
            className="card__confirm"
            onClick={() => {
              handleConfirmClick();
            }}
            style={{
              position: "fixed",
              bottom: "10px",
              width: matches ? "35%" : "200px",
            }}
          >
            {t("Confirm Purchase")}
          </button> :
          <button
          className="card__confirm"
          onClick={() => {
            setLoginShow(true);
          }}
          style={{
            position: "fixed",
            bottom: "10px",
            width: matches ? "35%" : "200px",
          }}
        >
          {t("Login To Confirm Purchase")}
        </button>}
        </div>
      )}
    </div>
  );
};

import { useAuth } from "hooks";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { NovelPdf } from "./NovelPdf";
import { Loader } from "components/Loader";
import { t } from "i18next";
import { useParams  } from "react-router-dom";
export const NovelsDahboard = () => {
  const { id } = useParams();
  const currentLang = i18next.language;
  const { bearerToken } = useAuth();
  const [boughtNovels, setBoughtNovels] = useState([]);
  const [selectedNovel, setSelectedNovel] = useState(null);
  const [isLoading , setIsLoading] = useState(false);
  const [errorFetching , setErrorFetching] = useState('');

  const fetchContent = useCallback(() => {
    const headers = {
      lang: currentLang,
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` })
    };
    const options = {
      headers: headers,
    };
    const url = `${process.env.REACT_APP_BASE_URL}/get-bought-novels`;
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setBoughtNovels(data.data);
          setErrorFetching('');
          const pdf = data?.data?.find(item => item.id == id);
          if(pdf) {
            // console.log('pdf', pdf)
            handleCardClick(pdf);
          }
        } else {
          throw data.message;
        }
      })
      .catch((error) => {
        // setErrorFetching(t('Somthing went wrong please check your internet connection or try again later'));
        console.error("Error fetching data:", error);
      });
  }, [currentLang, bearerToken]);

  const handleCardClick = (card) => {
    setSelectedNovel(card);
  };

  const handleBackClick = () => {
    setSelectedNovel(null);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchContent();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [fetchContent]);
  return selectedNovel ? (
    <NovelPdf card={selectedNovel} HandleBackClick={handleBackClick} />
  ) : (
    <>
    {
      isLoading ? <Loader/>
      :
      <div className="dashboard-novels">
        {errorFetching && <p style={{color:"red" , background:"white" , borderRadius:"0.5rem" , padding:"1rem"}}>{errorFetching}</p>}
      {boughtNovels.length > 0 ?  boughtNovels.map((card, key) => (
        <div
          className="card"
          key={key}
          onClick={() => {
            handleCardClick(card);
          }}
        >
          <div className="card__image">
            <img
              src={`${process.env.REACT_APP_LARAVEL_BASE_URL}/${card.image}`}
              alt={card.title}
            />
          </div>
          <div className="card__title">
            <h3>{card.title}</h3>
          </div>
        </div>
      )):
      <>
      
      <svg width="300px" height="375px" viewBox="0 0 837 1045" version="1.1" >
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
              <path d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z" id="Polygon-1" stroke="#007FB2" strokeWidth="6" ></path>
              <path d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z" id="Polygon-2" stroke="#EF4A5B" strokeWidth="6" ></path>
              <path d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z" id="Polygon-3" stroke="#795D9C" strokeWidth="6" ></path>
              <path d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z" id="Polygon-4" stroke="#F2773F" strokeWidth="6" ></path>
              <path d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z" id="Polygon-5" stroke="#36B455" strokeWidth="6" ></path>
          </g>
      </svg>
      <div className="message-box">
        <h1>{t('No Novels Found')}</h1>
        {/* <div className="buttons-con">
          <div className="action-link-wrap">
            <button className="link-button link-back-button" onClick={()=>{}}>{t('Show Novels')}</button>
          </div>
        </div> */}
      </div>
      
      </>}
    </div>
    }
    </>
    
  );
};
